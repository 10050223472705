export const onClientEntry = () => {
  const isDebugMode = document.location.search.includes('show_debug_info');

  if (isDebugMode) {
    const params = {
      UMBRACO_BASE_URL: process.env.GATSBY_UMBRACO_BASE_URL,
      NODE_ENV: process.env.NODE_ENV,
    };

    let parsedEnvs = {};

    try {
      parsedEnvs = JSON.parse(process.env.GATSBY_DEBUG as string);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(
        'GATSBY_DEBUG not passed or its not contain a JSON object',
        err,
        process.env.GATSBY_DEBUG
      );
    }

    // eslint-disable-next-line no-console
    console.log({ ...params, ...parsedEnvs });
  }
};
