import ReactDOM from 'react-dom';
import { loadableReady } from '@loadable/component';

import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/700.css';
import '@fontsource/space-grotesk/600.css';

const handleGatsbyAnnouncer = () => {
  const gatsbyAnnouncer = document.querySelector('#gatsby-announcer');
  if (gatsbyAnnouncer) {
    gatsbyAnnouncer.setAttribute('role', 'navigation');
  }
};

export const replaceHydrateFunction = () => (element, container, callback) => {
  loadableReady(() => {
    ReactDOM.hydrate(element, container, callback);
  });
};

export const onInitialClientRender = () => {
  handleGatsbyAnnouncer();
};

export const wrapRootElement = ({ element }) => element;
